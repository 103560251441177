// ---------------------------------------------
// BOOTSTRAP OVERRIDES / EXTENSIONS
// ---------------------------------------------


// -half classes add one half of width % by Bootstrap calcs
.row-fluid .span-half { width: 2.991452991452992%; }
.row-fluid .span4-half { width: 34.61538461538461%; }

// -quarter classes...
.row-fluid .span-quarter { width: 1.495726495726496%; }
.row-fluid .span4-3quarter { width: 33.11965811965811%; }


// ---------------------------------------------
// GLOBALS
// ---------------------------------------------
.g-no-border { border: none; }





// ---------------------------------------------
// STYLES
// ---------------------------------------------


// -----------------------------------------------------------------------------------------
// GLOBAL MESSAGE
// -----------------------------------------------------------------------------------------

// BLOCK ------------------------
.global-message{
    border: 1px solid #9cc9b0;
    padding: .5em;
    margin-bottom: 1em;
    background-color: #d8e5de;
    text-align: center;

    &.--smaller{
        font-size: .85em;
    }
}



// -----------------------------------------------------------------------------------------
// PRODUCTS / PARTS LIST SCHEMATICS
// -----------------------------------------------------------------------------------------

// BLOCK ------------------------
.product-list__item {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #b8daee;
}

// BLOCK ------------------------
.product-image__img{
    border: 1px solid #b8daee;
    margin-bottom: 10px;
}

.product-image__caption{
   font-size: .75em;
}


// BLOCK ------------------------
.product-partslist{
    list-style: none;
    margin: 0;
    padding: 0;
}

// ELEMENT
.product-partslist__item{
    padding: 5px 5px 0 5px;
    border-bottom: 1px solid #b8daee
}

.product-partslist__calloutid{
    color: #333;
    font-weight: bold;
}

.product-partslist__description{
    font-style: italic;
}

.product-partslist__sparepart{
    font-weight: bold;
    white-space: nowrap;
}



// -----------------------------------------------------------------------------------------
// DEALER PRICING
// -----------------------------------------------------------------------------------------

// BLOCK ------------------------
.dealerform {

}

// ELEMENT ------------------------
.dealerform__fieldset {
    border: 1px solid #9cc9b0;
    padding: 1.5em;
    margin-bottom: 2em;
    background-color: #d8e5de;
}

.dealerform__label {
    //color: #999;
}

input.dealerform__input[type="text"] {
    width:100%;
    height: 2.5em;
    box-sizing: border-box;
}

// BLOCK ------------------------
.dealerprice-list {
    margin: 0;
    list-style:none;
    margin-bottom: 25px;
}

// ELEMENT ------------------------
.dealerprice-list__head {
    margin-bottom: 5px;
}

.dealerprice-list__item {
    padding-bottom: 15px;
    margin-bottom: 5px;
    border-bottom: 1px solid #b8daee;
}

.dealerprice-list__pdf {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../images/icons-sprites.png") 0 -162px no-repeat;
    margin-right: 5px;
  }

 
.dealerprice-list__xls {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../images/icons-sprites--2017.png") 0 -16px no-repeat;
    margin-right: 5px;
  } 